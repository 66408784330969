import { SUPPLIER_PRODUCTS_TABLE_HEADERS, COLUMN_CATEGORIES } from './supplierProductsTableConstants';
import i18n from '@/imports/startup/client/i18n';
import { EXCEL_TYPES } from '../../excel';

export const getDefaultPreset = (columns) => {
  const columnsToShowFromDefaultCategory = [
    SUPPLIER_PRODUCTS_TABLE_HEADERS.SKU,
    SUPPLIER_PRODUCTS_TABLE_HEADERS.PRODUCT,
    SUPPLIER_PRODUCTS_TABLE_HEADERS.AVG_PERIOD_PRICE,
    SUPPLIER_PRODUCTS_TABLE_HEADERS.QUANTITY,
    SUPPLIER_PRODUCTS_TABLE_HEADERS.TOTAL_PURCHASES,
    SUPPLIER_PRODUCTS_TABLE_HEADERS.LAST_ORDER_ITEM_SUPPLIER,
  ];

  return columns.map((column) => {
    if (column.category === COLUMN_CATEGORIES.SIMULATION) {
      return {
        ...column,
        hidden: true,
        checked: false,
      };
    }

    if (column.category === COLUMN_CATEGORIES.PREVIOUS_PERIOD_PRODUCTS_PURCHASED) {
      return {
        ...column,
        hidden: false,
        checked: true,
      };
    }

    if (columnsToShowFromDefaultCategory.includes(column.key)) {
      return {
        ...column,
        hidden: false,
        checked: true,
      };
    } else {
      return {
        ...column,
        hidden: true,
        checked: false,
      };
    }
  });
};

export const getSimulationPreset = (columns) => {
  const columnsToShowFromDefaultCategory = [
    SUPPLIER_PRODUCTS_TABLE_HEADERS.SKU,
    SUPPLIER_PRODUCTS_TABLE_HEADERS.PRODUCT,
    SUPPLIER_PRODUCTS_TABLE_HEADERS.AVG_PERIOD_PRICE,
    SUPPLIER_PRODUCTS_TABLE_HEADERS.QUANTITY,
    SUPPLIER_PRODUCTS_TABLE_HEADERS.TOTAL_PURCHASES,
  ];

  return columns.map((column) => {
    if (column.category === COLUMN_CATEGORIES.SIMULATION) {
      return {
        ...column,
        hidden: false,
        checked: true,
      };
    }

    if (column.category === COLUMN_CATEGORIES.PREVIOUS_PERIOD_PRODUCTS_PURCHASED) {
      return {
        ...column,
        hidden: false,
        checked: true,
      };
    }

    if (
      columnsToShowFromDefaultCategory.includes(column.key) &&
      column.category === COLUMN_CATEGORIES.PRODUCTS_PURCHASED
    ) {
      return { ...column, hidden: false, checked: true };
    } else {
      return { ...column, hidden: true, checked: false };
    }
  });
};

export const getComparisonPreset = (columns) => {
  const columnsToShowFromDefaultCategory = [
    SUPPLIER_PRODUCTS_TABLE_HEADERS.SKU,
    SUPPLIER_PRODUCTS_TABLE_HEADERS.PRODUCT,
    SUPPLIER_PRODUCTS_TABLE_HEADERS.AVG_PERIOD_PRICE,
    SUPPLIER_PRODUCTS_TABLE_HEADERS.QUANTITY,
    SUPPLIER_PRODUCTS_TABLE_HEADERS.TOTAL_PURCHASES,
  ];

  return columns.map((column) => {
    if (columnsToShowFromDefaultCategory.includes(column.key)) {
      return {
        ...column,
        hidden: false,
        checked: true,
      };
    }

    return {
      ...column,
      hidden: true,
      checked: false,
    };
  });
};

export const getSupplierProductsColumns = (activeSort) => {
  return [
    {
      header: i18n.t('terms.supplierTerms.supplierTermTable.header.sku'),
      key: SUPPLIER_PRODUCTS_TABLE_HEADERS.SKU,
      width: '120px',
      editable: false,
      dataKey: 'product',
      category: COLUMN_CATEGORIES.PRODUCTS_PURCHASED,
      subHeaderCustomClass: 'sub-header-bg',
    },
    {
      header: i18n.t('terms.supplierTerms.supplierTermTable.header.product'),
      key: SUPPLIER_PRODUCTS_TABLE_HEADERS.PRODUCT,
      width: '158px',
      sortCallback: (direction) =>
        (activeSort.value = {
          direction,
          columnKey: SUPPLIER_PRODUCTS_TABLE_HEADERS.PRODUCT,
        }),
      editable: false,
      dataKey: 'product',
      category: COLUMN_CATEGORIES.PRODUCTS_PURCHASED,
      subHeaderCustomClass: 'sub-header-bg',
    },
    {
      header: i18n.t('terms.supplierTerms.supplierTermTable.header.lastOrderItemSupplier'),
      key: SUPPLIER_PRODUCTS_TABLE_HEADERS.LAST_ORDER_ITEM_SUPPLIER,
      width: '164px',
      hidden: false,
      editable: true,
      type: EXCEL_TYPES.CURRENCY,
      category: COLUMN_CATEGORIES.PRODUCTS_PURCHASED,
      subHeaderCustomClass: 'sub-header-bg',
    },
    {
      header: i18n.t('terms.supplierTerms.supplierTermTable.header.lastOrderDate'),
      key: SUPPLIER_PRODUCTS_TABLE_HEADERS.LAST_ORDER_DATE,
      width: '137px',
      hidden: true,
      editable: true,
      type: EXCEL_TYPES.DATE,
      category: COLUMN_CATEGORIES.PRODUCTS_PURCHASED,
      subHeaderCustomClass: 'sub-header-bg',
    },
    {
      header: i18n.t('terms.supplierTerms.supplierTermTable.header.basePrice'),
      key: SUPPLIER_PRODUCTS_TABLE_HEADERS.BASE_PRICE,
      width: '164px',
      hidden: true,
      editable: true,
      type: EXCEL_TYPES.CURRENCY,
      category: COLUMN_CATEGORIES.PRODUCTS_PURCHASED,
      subHeaderCustomClass: 'sub-header-bg',
    },
    {
      header: i18n.t('terms.supplierTerms.supplierTermTable.header.discount'),
      key: SUPPLIER_PRODUCTS_TABLE_HEADERS.DISCOUNT,
      width: '180px',
      hidden: true,
      editable: true,
      type: EXCEL_TYPES.PERCENTAGE,
      category: COLUMN_CATEGORIES.PRODUCTS_PURCHASED,
      subHeaderCustomClass: 'sub-header-bg',
    },
    {
      header: i18n.t('terms.supplierTerms.supplierTermTable.header.amountOfAssociated'),
      key: SUPPLIER_PRODUCTS_TABLE_HEADERS.AMOUNT_OF_ASSOCIATED,
      width: '120px',
      hidden: true,
      editable: true,
      type: EXCEL_TYPES.CURRENCY,
      category: COLUMN_CATEGORIES.PRODUCTS_PURCHASED,
      subHeaderCustomClass: 'sub-header-bg',
    },
    {
      header: i18n.t('terms.supplierTerms.supplierTermTable.header.quantity'),
      key: SUPPLIER_PRODUCTS_TABLE_HEADERS.QUANTITY,
      width: '85px',
      isComparisonColumn: true,
      sortCallback: (direction) =>
        (activeSort.value = {
          direction,
          columnKey: SUPPLIER_PRODUCTS_TABLE_HEADERS.QUANTITY,
        }),
      hidden: false,
      editable: true,
      category: COLUMN_CATEGORIES.PRODUCTS_PURCHASED,
      subHeaderCustomClass: 'sub-header-bg',
    },
    {
      header: i18n.t('terms.supplierTerms.supplierTermTable.header.avgPeriodPrice'),
      key: SUPPLIER_PRODUCTS_TABLE_HEADERS.AVG_PERIOD_PRICE,
      width: '104px',
      isComparisonColumn: true,
      sortCallback: (direction) =>
        (activeSort.value = {
          direction,
          columnKey: SUPPLIER_PRODUCTS_TABLE_HEADERS.AVG_PERIOD_PRICE,
        }),
      hidden: false,
      editable: true,
      type: EXCEL_TYPES.CURRENCY,
      category: COLUMN_CATEGORIES.PRODUCTS_PURCHASED,
      subHeaderCustomClass: 'sub-header-bg',
    },
    {
      header: i18n.t('terms.supplierTerms.supplierTermTable.header.minPrice'),
      key: SUPPLIER_PRODUCTS_TABLE_HEADERS.MIN_PRICE,
      width: '130px',
      hidden: true,
      editable: true,
      type: EXCEL_TYPES.CURRENCY,
      category: COLUMN_CATEGORIES.PRODUCTS_PURCHASED,
      subHeaderCustomClass: 'sub-header-bg',
    },
    {
      header: i18n.t('terms.supplierTerms.supplierTermTable.header.maxPrice'),
      key: SUPPLIER_PRODUCTS_TABLE_HEADERS.MAX_PRICE,
      width: '130px',
      hidden: true,
      editable: true,
      type: EXCEL_TYPES.CURRENCY,
      category: COLUMN_CATEGORIES.PRODUCTS_PURCHASED,
      subHeaderCustomClass: 'sub-header-bg',
    },
    {
      header: i18n.t('terms.supplierTerms.supplierTermTable.header.totalPurchases'),
      key: SUPPLIER_PRODUCTS_TABLE_HEADERS.TOTAL_PURCHASES,
      width: '180px',
      isComparisonColumn: true,
      sortCallback: (direction) =>
        (activeSort.value = {
          direction,
          columnKey: SUPPLIER_PRODUCTS_TABLE_HEADERS.TOTAL_PURCHASES,
        }),
      hidden: false,
      editable: true,
      type: EXCEL_TYPES.CURRENCY,
      category: COLUMN_CATEGORIES.PRODUCTS_PURCHASED,
      subHeaderCustomClass: 'sub-header-bg',
    },
    {
      header: i18n.t('terms.supplierTerms.supplierTermTable.header.purchaseList'),
      key: SUPPLIER_PRODUCTS_TABLE_HEADERS.PURCHASE_LIST,
      width: '180px',
      hidden: true,
      editable: true,
      category: COLUMN_CATEGORIES.PRODUCTS_PURCHASED,
      subHeaderCustomClass: 'sub-header-bg',
    },
    {
      header: i18n.t('terms.supplierTerms.supplierTermTable.header.previousAvgPrice'),
      key: SUPPLIER_PRODUCTS_TABLE_HEADERS.PREV_PERIOD_AVG_PRICE,
      width: '160px',
      hidden: false,
      editable: true,
      type: EXCEL_TYPES.CURRENCY,
      category: COLUMN_CATEGORIES.PREVIOUS_PERIOD_PRODUCTS_PURCHASED,
      subHeaderCustomClass: 'sub-header-bg',
    },
    {
      header: i18n.t('terms.supplierTerms.supplierTermTable.header.totalDifference'),
      key: SUPPLIER_PRODUCTS_TABLE_HEADERS.PREVIOUS_PERIOD_DIFFERENCE,
      width: '160px',
      hidden: false,
      editable: true,
      type: EXCEL_TYPES.CURRENCY,
      category: COLUMN_CATEGORIES.PREVIOUS_PERIOD_PRODUCTS_PURCHASED,
      subHeaderCustomClass: 'sub-header-bg',
    },
    {
      header: i18n.t('terms.supplierTerms.supplierTermTable.header.annualImpact'),
      key: SUPPLIER_PRODUCTS_TABLE_HEADERS.ANNUAL_IMPACT,
      width: '170px',
      sortCallback: (direction) =>
        (activeSort.value = {
          direction,
          columnKey: SUPPLIER_PRODUCTS_TABLE_HEADERS.ANNUAL_IMPACT,
        }),
      hidden: false,
      editable: true,
      type: EXCEL_TYPES.CURRENCY,
      category: COLUMN_CATEGORIES.PREVIOUS_PERIOD_PRODUCTS_PURCHASED,
      subHeaderCustomClass: 'sub-header-bg',
    },
    {
      header: i18n.t('terms.supplierTerms.supplierTermTable.header.constantPrice'),
      key: SUPPLIER_PRODUCTS_TABLE_HEADERS.CONSTANT_PRICE,
      width: '200px',
      hidden: false,
      editable: true,
      type: EXCEL_TYPES.CURRENCY,
      category: COLUMN_CATEGORIES.SIMULATION,
      subHeaderCustomClass: 'sub-header-bg',
    },
    {
      header: i18n.t('terms.supplierTerms.supplierTermTable.header.totalDifference'),
      key: SUPPLIER_PRODUCTS_TABLE_HEADERS.TOTAL_DIFFERENCE,
      width: '112px',
      hidden: false,
      editable: true,
      type: EXCEL_TYPES.CURRENCY,
      category: COLUMN_CATEGORIES.SIMULATION,
      subHeaderCustomClass: 'sub-header-bg',
    },
    {
      header: i18n.t('terms.supplierTerms.supplierTermTable.header.annualImpactSim'),
      key: SUPPLIER_PRODUCTS_TABLE_HEADERS.ANNUAL_IMPACT_SIM,
      width: '150px',
      hidden: false,
      editable: true,
      type: EXCEL_TYPES.CURRENCY,
      category: COLUMN_CATEGORIES.SIMULATION,
      subHeaderCustomClass: 'sub-header-bg',
    },
  ];
};
