import i18n from '@/imports/startup/client/i18n';
import { exportToExcel } from '@/imports/ui/file_exports';
import { SUPPLIER_PRODUCTS_TABLE_HEADERS } from './supplierProductsTableConstants';
import { buildColumnsByTableViewForExcel } from '../../excel';
import { getAnnualImpact } from './supplierProductsTable';
import { MISSING_DATA_TEXT } from '@/modules/purchase-management/tools/constants';

import { getPurchaseListStatusByIsRecommended } from './supplierTableCells/PurchaseListCell';

export const exportSupplierProductsComparison = ({
  supplier,
  productItems,
  tableColumns,
  dateRanges,
  currencyFormat,
}) => {
  const amountOfComparison = Object.keys(productItems[0]).length;
  const columns = buildColumnsByTableViewForExcel({
    tableColumns,
    amountOfComparison,
    dateRanges,
    currencyFormat,
    columnsNotInComparison: [SUPPLIER_PRODUCTS_TABLE_HEADERS.SKU, SUPPLIER_PRODUCTS_TABLE_HEADERS.PRODUCT],
  });
  const translationKey = dateRanges.length === 3 ? 'productsComparisonThreePeriod' : 'productsComparisonTwoPeriod';
  const translationDateRanges = {
    firstPeriod: dateRanges[0],
    secondPeriod: dateRanges[1],
    thirdPeriod: dateRanges[2],
  };

  const metadata = {
    filename: i18n.t(`terms.supplierTerms.supplierTermTable.excel.fileNames.${translationKey}`, {
      supplier: supplier.name,
      ...translationDateRanges,
    }),
    sheetname: i18n.t('terms.supplierTerms.supplierTermTable.excel.productsSheetname'),
    direction: i18n.t('direction'),
    columns,
  };

  const data = productItems.map((comparisonItem) => {
    const comparisonItemValues = {};

    for (let i = 0; i < amountOfComparison; i++) {
      const { pricedOrderItemAverage, sumPrice, quantity } = comparisonItem[`comparison-${i}`];
      const avgPrice = Number(pricedOrderItemAverage);

      comparisonItemValues[`avgPeriodPrice-${i}`] = avgPrice ?? MISSING_DATA_TEXT;
      comparisonItemValues[`totalPurchases-${i}`] = sumPrice ?? MISSING_DATA_TEXT;
      comparisonItemValues[`quantity-${i}`] = quantity ?? MISSING_DATA_TEXT;
    }

    return {
      sku:
        comparisonItem['comparison-0'].product.sku ??
        comparisonItem['comparison-1'].product.sku ??
        comparisonItem['comparison-2']?.product.sku ??
        MISSING_DATA_TEXT,
      product:
        comparisonItem['comparison-0'].product.name ??
        comparisonItem['comparison-1'].product.name ??
        comparisonItem['comparison-2']?.product.name ??
        MISSING_DATA_TEXT,
      ...comparisonItemValues,
    };
  });

  exportToExcel(metadata, data);
};

const getRowDataFromComparisonObject = (rowData, isComparison) => {
  if (isComparison) {
    return rowData['comparison-0'] ?? rowData['comparison-1'] ?? rowData['comparison-2'];
  }

  return rowData;
};

export const exportSupplierProducts = ({
  supplier,
  productItems,
  tableColumns,
  dateRanges,
  returnOnlyDataAndColumns = false,
  currencyFormat,
}) => {
  const productKeys = Object.keys(productItems[0]);
  const isComparison = productKeys.includes('comparison-0');
  const amountOfComparison = isComparison ? productKeys.length : 1;
  const columnsInComparison = [
    SUPPLIER_PRODUCTS_TABLE_HEADERS.QUANTITY,
    SUPPLIER_PRODUCTS_TABLE_HEADERS.AVG_PERIOD_PRICE,
    SUPPLIER_PRODUCTS_TABLE_HEADERS.TOTAL_PURCHASES,
  ];
  const columnsNotInComparison = Object.values(SUPPLIER_PRODUCTS_TABLE_HEADERS).filter(
    (header) => !columnsInComparison.includes(header)
  );

  const columns = buildColumnsByTableViewForExcel({
    tableColumns,
    amountOfComparison: isComparison ? amountOfComparison : undefined,
    dateRanges,
    columnsNotInComparison,
    currencyFormat,
  });

  const discountColumnIndex = columns.findIndex((column) => column.key === SUPPLIER_PRODUCTS_TABLE_HEADERS.DISCOUNT);
  const discountColumn = discountColumnIndex === -1 ? undefined : columns[discountColumnIndex];
  const updatedColumns = columns.filter((column) => column.key !== SUPPLIER_PRODUCTS_TABLE_HEADERS.DISCOUNT);
  if (discountColumn) {
    const itemDiscountColumn = {
      ...discountColumn,
      label: i18n.t('terms.supplierTerms.supplierTermTable.itemDiscountInLastOrder'),
      key: 'itemDiscount',
    };
    const orderDiscountColumn = {
      ...discountColumn,
      label: i18n.t('terms.supplierTerms.supplierTermTable.orderDiscountInLastOrder'),
      key: 'orderDiscount',
    };
    updatedColumns.splice(discountColumnIndex, 0, itemDiscountColumn);
    updatedColumns.splice(discountColumnIndex + 1, 0, orderDiscountColumn);
  }

  const data = productItems.map((rowData) => {
    const {
      product,
      maxDateOrderItem,
      sumPrice,
      totalBeforeSimulation,
      pricedOrderItemAverage,
      simulatedPrice,
      isRecommended,
      ...rest
    } = getRowDataFromComparisonObject(rowData, isComparison);
    const itemDiscountExists = maxDateOrderItem && maxDateOrderItem.discount;
    const orderDiscountExists = maxDateOrderItem && maxDateOrderItem.orderDiscountRate;
    const itemDiscountRate = maxDateOrderItem.discount / 100;
    const annualImpactSim = getAnnualImpact({
      quantity: rest.projectedQuantity,
      currentAverage: simulatedPrice,
      previousAverage: pricedOrderItemAverage,
    });

    const comparisonItemValues = {};

    if (isComparison) {
      for (let i = 0; i < amountOfComparison; i++) {
        const pricedOrderItemAverage = rowData[`comparison-${i}`]
          ? rowData[`comparison-${i}`].pricedOrderItemAverage
          : MISSING_DATA_TEXT;
        const sumPrice = rowData[`comparison-${i}`] ? rowData[`comparison-${i}`].sumPrice : MISSING_DATA_TEXT;
        const quantity = rowData[`comparison-${i}`] ? rowData[`comparison-${i}`].quantity : MISSING_DATA_TEXT;

        const avgPrice = pricedOrderItemAverage !== MISSING_DATA_TEXT ? Number(pricedOrderItemAverage) : null;

        comparisonItemValues[`avgPeriodPrice-${i}`] = avgPrice ?? MISSING_DATA_TEXT;
        comparisonItemValues[`totalPurchases-${i}`] = sumPrice ?? MISSING_DATA_TEXT;
        comparisonItemValues[`quantity-${i}`] = quantity ?? MISSING_DATA_TEXT;
      }
    }

    const purchaseListStatus = getPurchaseListStatusByIsRecommended(isRecommended);
    const purchaseListStatusText = i18n.t(`terms.supplierTerms.supplierTermTable.${purchaseListStatus}`);

    return {
      sku: product.sku,
      product: product.name,
      lastOrderItemSupplier: maxDateOrderItem ? maxDateOrderItem.netAmountPerUnit : MISSING_DATA_TEXT,
      lastOrderDate: maxDateOrderItem ? new Date(maxDateOrderItem.orderDate) : MISSING_DATA_TEXT,
      totalPurchases: sumPrice,
      avgPeriodPrice: pricedOrderItemAverage,
      basePrice: maxDateOrderItem ? maxDateOrderItem.price : MISSING_DATA_TEXT,
      itemDiscount: itemDiscountExists ? itemDiscountRate : MISSING_DATA_TEXT,
      orderDiscount: orderDiscountExists ? maxDateOrderItem.orderDiscountRate : MISSING_DATA_TEXT,
      amountOfAssociated: maxDateOrderItem
        ? maxDateOrderItem.associatedIntegralItemsPricePerUnit ?? MISSING_DATA_TEXT
        : MISSING_DATA_TEXT,
      constantPrice: simulatedPrice ?? MISSING_DATA_TEXT,
      totalBeforeSim: totalBeforeSimulation ?? MISSING_DATA_TEXT,
      annualImpactSim: annualImpactSim ?? MISSING_DATA_TEXT,
      annualImpact: rest.projectedImpact ?? MISSING_DATA_TEXT,
      previousPeriodAvgPrice: rest.previousAveragePrice ?? MISSING_DATA_TEXT,
      previousPeriodDifference: rest.totalPeriodDifference ?? MISSING_DATA_TEXT,
      purchaseList: purchaseListStatusText,
      ...rest,
      ...(isComparison && { ...comparisonItemValues }),
    };
  });

  if (returnOnlyDataAndColumns) {
    return {
      columns: updatedColumns,
      data,
    };
  }

  const metadata = {
    filename: i18n.t('terms.supplierTerms.supplierTermTable.excel.fileNames.productsSummary', {
      supplier: supplier.name,
    }),
    sheetname: i18n.t('terms.supplierTerms.supplierTermTable.excel.productsSheetname'),
    direction: i18n.t('direction'),
    columns: updatedColumns,
  };

  exportToExcel(metadata, data);
};
