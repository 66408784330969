import { formatNumberOrDash, formatDateOrDash, formatCurrencyOrDash } from '../../purchaseManagementFormatters';
import { SUPPLIER_PRODUCTS_TABLE_HEADERS } from './supplierProductsTableConstants';
import { cellRenderersComponentNames } from './supplierTableCells';

const getCellName = (name) => {
  return `cell-${name}`;
};

export const DATA_TO_RETRIEVE = {
  ROW: 'rowData',
  ORDER_ITEM: 'orderItem',
  SPECIFIC_DATA: 'specificData',
};

export const getSummaryCellConfiguration = (currencyFormat) => [
  {
    componentName: cellRenderersComponentNames.sku,
    slotName: getCellName(SUPPLIER_PRODUCTS_TABLE_HEADERS.SKU),
    dataToRetrieve: DATA_TO_RETRIEVE.ROW,
  },
  {
    componentName: cellRenderersComponentNames.productName,
    slotName: getCellName(SUPPLIER_PRODUCTS_TABLE_HEADERS.PRODUCT),
    dataToRetrieve: DATA_TO_RETRIEVE.ROW,
  },
  {
    componentName: cellRenderersComponentNames.formattedParagraph,
    slotName: getCellName(SUPPLIER_PRODUCTS_TABLE_HEADERS.LAST_ORDER_ITEM_SUPPLIER),
    dataToRetrieve: DATA_TO_RETRIEVE.SPECIFIC_DATA,
    dataSelectorForGenericCell: 'maxDateOrderItem',
    extraProps: { dataKey: 'netAmountPerUnit', formatter: (number) => formatCurrencyOrDash(number, currencyFormat) },
  },
  {
    componentName: cellRenderersComponentNames.formattedParagraph,
    slotName: getCellName(SUPPLIER_PRODUCTS_TABLE_HEADERS.BASE_PRICE),
    dataToRetrieve: DATA_TO_RETRIEVE.SPECIFIC_DATA,
    dataSelectorForGenericCell: 'maxDateOrderItem',
    extraProps: { dataKey: 'price', formatter: (number) => formatCurrencyOrDash(number, currencyFormat) },
  },
  {
    componentName: cellRenderersComponentNames.formattedParagraph,
    slotName: getCellName(SUPPLIER_PRODUCTS_TABLE_HEADERS.MAX_PRICE),
    dataToRetrieve: DATA_TO_RETRIEVE.SPECIFIC_DATA,
    dataSelectorForGenericCell: 'maxPrice',
    extraProps: { formatter: (number) => formatCurrencyOrDash(number, currencyFormat) },
  },
  {
    componentName: cellRenderersComponentNames.formattedParagraph,
    slotName: getCellName(SUPPLIER_PRODUCTS_TABLE_HEADERS.MIN_PRICE),
    dataToRetrieve: DATA_TO_RETRIEVE.SPECIFIC_DATA,
    dataSelectorForGenericCell: 'minPrice',
    extraProps: { formatter: (number) => formatCurrencyOrDash(number, currencyFormat) },
  },
  {
    componentName: cellRenderersComponentNames.formattedParagraph,
    slotName: getCellName(SUPPLIER_PRODUCTS_TABLE_HEADERS.LAST_ORDER_DATE),
    dataToRetrieve: DATA_TO_RETRIEVE.SPECIFIC_DATA,
    dataSelectorForGenericCell: 'maxDateOrderItem',
    extraProps: { dataKey: 'orderDate', formatter: formatDateOrDash },
  },
  {
    componentName: cellRenderersComponentNames.amountOfAssociated,
    slotName: getCellName(SUPPLIER_PRODUCTS_TABLE_HEADERS.AMOUNT_OF_ASSOCIATED),
    dataToRetrieve: DATA_TO_RETRIEVE.ORDER_ITEM,
  },
  {
    componentName: cellRenderersComponentNames.discount,
    slotName: getCellName(SUPPLIER_PRODUCTS_TABLE_HEADERS.DISCOUNT),
    dataToRetrieve: DATA_TO_RETRIEVE.ORDER_ITEM,
    extraProps: { offset: -50 },
  },
];

export const getPreviousPeriodCellConfiguration = (currencyFormat) => [
  {
    componentName: cellRenderersComponentNames.formattedParagraph,
    slotName: getCellName(SUPPLIER_PRODUCTS_TABLE_HEADERS.PREV_PERIOD_AVG_PRICE),
    dataToRetrieve: DATA_TO_RETRIEVE.SPECIFIC_DATA,
    dataSelectorForGenericCell: 'previousAveragePrice',
    extraProps: {
      formatter: (number) => formatCurrencyOrDash(number, currencyFormat),
      headerKey: SUPPLIER_PRODUCTS_TABLE_HEADERS.AVG_PERIOD_PRICE,
    },
  },
  {
    componentName: cellRenderersComponentNames.previousPeriodDifference,
    slotName: getCellName(SUPPLIER_PRODUCTS_TABLE_HEADERS.PREVIOUS_PERIOD_DIFFERENCE),
    dataToRetrieve: DATA_TO_RETRIEVE.ROW,
  },
  {
    componentName: cellRenderersComponentNames.annualImpact,
    slotName: getCellName(SUPPLIER_PRODUCTS_TABLE_HEADERS.ANNUAL_IMPACT),
    dataSelectorForGenericCell: 'projectedImpact',
    dataToRetrieve: DATA_TO_RETRIEVE.SPECIFIC_DATA,
  },
];

export const getComparisonCellConfiguration = ({
  amountOfComparisons,
  getComparisonItem,
  getWidth,
  sumPurchases,
  calculatePercentage,
  currencyFormat,
}) => [
  {
    componentName: cellRenderersComponentNames.formattedParagraphComparison,
    slotName: getCellName(SUPPLIER_PRODUCTS_TABLE_HEADERS.QUANTITY),
    comparisonCell: true,
    dataToRetrieve: DATA_TO_RETRIEVE.ROW,
    extraProps: {
      dataKey: 'quantity',
      formatter: formatNumberOrDash,
      headerKey: SUPPLIER_PRODUCTS_TABLE_HEADERS.QUANTITY,
      amountOfComparisons,
      getComparisonItem,
      getWidth,
    },
  },
  {
    componentName: cellRenderersComponentNames.formattedParagraphComparison,
    slotName: getCellName(SUPPLIER_PRODUCTS_TABLE_HEADERS.AVG_PERIOD_PRICE),
    comparisonCell: true,
    dataToRetrieve: DATA_TO_RETRIEVE.ROW,
    extraProps: {
      dataKey: 'pricedOrderItemAverage',
      formatter: (number) => formatCurrencyOrDash(number, currencyFormat),
      headerKey: SUPPLIER_PRODUCTS_TABLE_HEADERS.AVG_PERIOD_PRICE,
      amountOfComparisons,
      getComparisonItem,
      getWidth,
    },
  },
  {
    componentName: cellRenderersComponentNames.totalPurchasesComparison,
    comparisonCell: true,
    dataToRetrieve: DATA_TO_RETRIEVE.ROW,
    slotName: getCellName(SUPPLIER_PRODUCTS_TABLE_HEADERS.TOTAL_PURCHASES),
    extraProps: {
      amountOfComparisons,
      getComparisonItem,
      getWidth,
      sumPurchases,
      calculatePercentage,
    },
  },
];

export const getSimulationCellConfiguration = (currencyFormat) => [
  {
    componentName: cellRenderersComponentNames.formattedParagraph,
    slotName: getCellName(SUPPLIER_PRODUCTS_TABLE_HEADERS.TOTAL_DIFFERENCE),
    dataSelectorForGenericCell: 'totalDifference',
    dataToRetrieve: DATA_TO_RETRIEVE.SPECIFIC_DATA,
    extraProps: { formatter: (number) => formatCurrencyOrDash(number, currencyFormat) },
  },
  {
    componentName: cellRenderersComponentNames.annualImpact,
    slotName: getCellName(SUPPLIER_PRODUCTS_TABLE_HEADERS.ANNUAL_IMPACT_SIM),
    dataToRetrieve: DATA_TO_RETRIEVE.SPECIFIC_DATA,
    dataSelectorForGenericCell: 'projectedImpactSim',
  },
];
