import { mutateComparisonItems } from '../../purchaseManagement';
import { clone } from 'ramda';
import { SUPPLIER_PRODUCTS_TABLE_HEADERS, PRICE_MODE_NAMES } from './supplierProductsTableConstants';

export const getAnnualImpact = ({ quantity, currentAverage, previousAverage }) => {
  if (!currentAverage || !previousAverage || !quantity) return null;

  const fullDifference = currentAverage - previousAverage;
  const roundedDifference = Math.round(fullDifference * 100) / 100;
  if (roundedDifference === 0) return null;

  const annualImpact = Math.round(fullDifference * (quantity * 12) * 100) / 100;

  return annualImpact === 0 ? null : annualImpact;
};

export const getDifferenceForPeriod = ({ previousAveragePrice, currentAveragePrice, quantity }) => {
  if (!previousAveragePrice || !currentAveragePrice || !quantity) return null;

  const fullDifference = currentAveragePrice - previousAveragePrice;
  const difference = Math.round(fullDifference * 100) / 100;

  const totalDifference = difference * quantity;

  return totalDifference === 0 ? null : totalDifference;
};

export const calculatePercentageChangeInPrice = (price1, price2) => {
  if (!price2 || !price1) return null;
  const percentage = Math.round(((price1 - price2) / price2) * 10000) / 100;
  if (percentage === 0) {
    return null;
  }

  return percentage;
};

const getSupplierProductsComparisonFunction = (direction, columnKey) => {
  switch (columnKey) {
    case SUPPLIER_PRODUCTS_TABLE_HEADERS.PRODUCT:
      return (a, b) => (a.product?.name ? a.product.name.localeCompare(b.product.name) : 0) * direction;

    case SUPPLIER_PRODUCTS_TABLE_HEADERS.QUANTITY:
      return (a, b) => (a.quantity - b.quantity) * direction;

    case SUPPLIER_PRODUCTS_TABLE_HEADERS.AVG_PERIOD_PRICE:
      return (a, b) => {
        const avgA = Number(a.pricedOrderItemAverage);
        const avgB = Number(b.pricedOrderItemAverage);
        return (avgA - avgB) * direction;
      };

    case SUPPLIER_PRODUCTS_TABLE_HEADERS.TOTAL_PURCHASES:
      return (a, b) => (a.sumPrice - b.sumPrice) * direction || a.product.id.localeCompare(b.product.id) * direction;

    case SUPPLIER_PRODUCTS_TABLE_HEADERS.ANNUAL_IMPACT:
      return (a, b) =>
        (a.projectedImpact - b.projectedImpact) * direction || a.product.id.localeCompare(b.product.id) * direction;

    default:
      return () => 0;
  }
};

export const mutatedSimulationProductItems = ({ simulationAggregation, activeSort, productSearchFilter }) => {
  if (!simulationAggregation?.previousPeriodPurchaseSummary) return [];
  const previousPeriodAverageProducts = simulationAggregation?.previousPeriodPurchaseSummary;

  const dataWithPreviousAverage = addPreviousPeriodData(
    simulationAggregation.productPurchaseSummary,
    previousPeriodAverageProducts
  );

  return mutatedProductItems(dataWithPreviousAverage, activeSort, productSearchFilter);
};

export const mutatedProductItems = (products, activeSort, productSearchFilter) => {
  return products
    .filter(({ product }) =>
      productSearchFilter
        ? product?.name?.includes(productSearchFilter) || product?.sku?.includes(productSearchFilter)
        : true
    )
    .map((orderItem) => {
      let simulatedPrice = orderItem.simulatedPrice;
      let simulationMode = orderItem.simulationMode;
      if (orderItem.rendered && !simulatedPrice) {
        simulatedPrice = null;
      } else if (!simulatedPrice) {
        simulatedPrice = orderItem.previousAveragePrice;
        simulationMode = PRICE_MODE_NAMES.PREV_PERIOD_AVG_PRICE;
      }

      const totalBeforeSimulation = simulatedPrice ? simulatedPrice * orderItem.quantity : orderItem.sumPrice;
      const parsedSumPrice = Math.round(orderItem.sumPrice * 100) / 100;

      const totalDifference = getDifferenceForPeriod({
        previousAveragePrice: orderItem.pricedOrderItemAverage,
        currentAveragePrice: simulatedPrice,
        quantity: orderItem.quantity,
      });
      const totalPeriodDifference = getDifferenceForPeriod({
        previousAveragePrice: orderItem.previousAveragePrice,
        currentAveragePrice: orderItem.pricedOrderItemAverage,
        quantity: orderItem.quantity,
      });

      const projectedImpact = getAnnualImpact({
        quantity: orderItem.projectedQuantity,
        currentAverage: orderItem.pricedOrderItemAverage,
        previousAverage: orderItem.previousAveragePrice,
      });
      const projectedImpactSim = getAnnualImpact({
        quantity: orderItem.projectedQuantity,
        currentAverage: simulatedPrice,
        previousAverage: orderItem.pricedOrderItemAverage,
      });

      return {
        ...orderItem,
        sumPrice: parsedSumPrice,
        totalBeforeSimulation,
        totalDifference,
        totalPeriodDifference,
        minPrice: orderItem.minPriceOrderItem?.netAmountPerUnit,
        maxPrice: orderItem.maxPriceOrderItem?.netAmountPerUnit,
        simulatedPrice,
        simulationMode,
        projectedImpact,
        projectedImpactSim,
      };
    })
    .sort(getSupplierProductsComparisonFunction(activeSort.direction, activeSort.columnKey));
};

export const mutateProductComparisonItems = (objectifiedData, activeSort, searchFilter) => {
  return mutateComparisonItems({
    objectifiedData,
    activeSort,
    searchFilter,
    key: 'product',
    mutationCallback: mutatedProductItems,
  });
};

export const addPreviousPeriodData = (currentPeriodData, previousPeriodData) => {
  return currentPeriodData.map((orderItem) => {
    const previousOrderItem = previousPeriodData.find(
      (previousOrderItemIter) => previousOrderItemIter.productId === orderItem.product.id
    );
    const clonedOrderItem = clone(orderItem);

    if (clonedOrderItem.maxDateOrderItem.items && clonedOrderItem.maxDateOrderItem.items.length > 0) {
      const updatedAssociatedItems = clonedOrderItem.maxDateOrderItem.items.map((associatedItem) => {
        if (associatedItem.type !== 'associatedIntegral') return associatedItem;

        const unitOfMeasure = Math.round((associatedItem.quantity / orderItem.maxDateOrderItem.quantity) * 100) / 100;

        return {
          ...associatedItem,
          uom: unitOfMeasure,
        };
      });

      clonedOrderItem.maxDateOrderItem.items = updatedAssociatedItems;
    }

    if (!previousOrderItem) {
      return {
        ...clonedOrderItem,
        averagePercentageChange: null,
        previousAveragePrice: null,
        projectedQuantity: orderItem.monthlyAverageTotalQuantity,
      };
    }

    let averagePercentageChange = null;
    if (clonedOrderItem.pricedOrderItemAverage && previousOrderItem.pricedOrderItemAverage) {
      averagePercentageChange =
        Math.round(
          ((clonedOrderItem.pricedOrderItemAverage - previousOrderItem.pricedOrderItemAverage) /
            previousOrderItem.pricedOrderItemAverage) *
            10000
        ) / 100;
      if (averagePercentageChange === 0) {
        averagePercentageChange = null;
      }
    }

    return {
      ...clonedOrderItem,
      averagePercentageChange,
      previousAveragePrice: previousOrderItem.pricedOrderItemAverage,
      projectedQuantity: orderItem.monthlyAverageTotalQuantity,
    };
  });
};

export const mutateProductSummary = ({ data, activeSort, searchFilter }) => {
  if (!data.productPurchaseSummary || !data.previousPeriodPurchaseSummary) {
    return [];
  }

  const dataWithPreviousAverage = addPreviousPeriodData(
    data.productPurchaseSummary,
    data.previousPeriodPurchaseSummary
  );

  return mutatedProductItems(dataWithPreviousAverage, activeSort, searchFilter);
};
