export const SUPPLIER_PRODUCTS_TABLE_HEADERS = {
  ASSOCIATED: 'associated',
  PRODUCT: 'product',
  TERMS: 'terms',
  AGREEMENT: 'agreement',
  QUANTITY: 'quantity',
  AVG_PERIOD_PRICE: 'avgPeriodPrice',
  PREV_PERIOD_AVG_PRICE: 'previousPeriodAvgPrice',
  MIN_PRICE: 'minPrice',
  MAX_PRICE: 'maxPrice',
  TOTAL_PURCHASES: 'totalPurchases',
  LAST_ORDER_ITEM_SUPPLIER: 'lastOrderItemSupplier',
  LAST_ORDER_DATE: 'lastOrderDate',
  SKU: 'sku',
  DISCOUNT: 'discount',
  BASE_PRICE: 'basePrice',
  AMOUNT_OF_ASSOCIATED: 'amountOfAssociated',
  CONSTANT_PRICE: 'constantPrice',
  TOTAL_BEFORE_SIM: 'totalBeforeSim',
  TOTAL_DIFFERENCE: 'totalDifference',
  ANNUAL_IMPACT: 'annualImpact',
  ANNUAL_IMPACT_SIM: 'annualImpactSim',
  PREVIOUS_PERIOD_DIFFERENCE: 'previousPeriodDifference',
  PURCHASE_LIST: 'purchaseList',
};

export const PRICE_MODE_NAMES = {
  FIRST_PRICE: 'firstPriceInPeriod',
  LAST_PRICE: 'lastPriceInPeriod',
  LOWEST_PRICE: 'lowestPriceInPeriod',
  HIGHEST_PRICE: 'highestPriceInPeriod',
  PREV_PERIOD_AVG_PRICE: 'previousAveragePrice',
  CUSTOM: 'custom',
  DISABLED: 'disabled',
};

export const priceModes = [
  {
    name: PRICE_MODE_NAMES.FIRST_PRICE,
    arrowDir: 'right',
    label: 'terms.supplierTerms.supplierTermTable.simulation.priceLabels.firstPriceInPeriod',
  },
  {
    name: PRICE_MODE_NAMES.LAST_PRICE,
    arrowDir: 'left',
    label: 'terms.supplierTerms.supplierTermTable.simulation.priceLabels.lastPriceInPeriod',
  },
  {
    name: PRICE_MODE_NAMES.LOWEST_PRICE,
    arrowDir: 'down',
    label: 'terms.supplierTerms.supplierTermTable.simulation.priceLabels.lowestPriceInPeriod',
  },
  {
    name: PRICE_MODE_NAMES.HIGHEST_PRICE,
    arrowDir: 'up',
    label: 'terms.supplierTerms.supplierTermTable.simulation.priceLabels.highestPriceInPeriod',
  },
  {
    name: PRICE_MODE_NAMES.PREV_PERIOD_AVG_PRICE,
    label: 'terms.supplierTerms.supplierTermTable.simulation.priceLabels.previousAvgPrice',
  },
  {
    name: PRICE_MODE_NAMES.CUSTOM,
    label: 'terms.supplierTerms.supplierTermTable.simulation.priceLabels.custom',
    notAll: true,
  },
  {
    name: PRICE_MODE_NAMES.DISABLED,
    label: 'terms.supplierTerms.supplierTermTable.simulation.priceLabels.disabled',
    notPopover: true,
  },
];

export const COLUMN_CATEGORIES = {
  PRODUCTS_PURCHASED: 'productsPurchased',
  PREVIOUS_PERIOD_PRODUCTS_PURCHASED: 'previousPeriodProductsPurchased',
  SIMULATION: 'simulation',
  COMPARISON: 'comparison',
};

export const SUPPLIER_PRODUCT_WITH_NO_DATA = {
  product: {
    id: null,
    name: null,
    sku: null,
  },
  maxDateOrderItem: {
    orderId: null,
    orderDate: null,
    netAmountPerUnit: null,
    netPrice: null,
    associatedIntegralItemsPricePerUnit: null,
    price: null,
    discount: null,
    quantity: null,
    items: {
      productId: null,
      price: null,
      product: {
        name: null,
      },
      quantity: null,
      type: null,
    },
  },
  quantity: null,
  sumPrice: null,
  numberOfOrders: null,
};
